import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Avatar, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import mainLogo from './images/Diver-Logo.png';
import Form from './login/form';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#0A47C1'
  }
}));

function Login() {
  const [flashMessage, setFlashMessage] = useState([]);

  useEffect(() => {
    setFlashMessage(JSON.parse(window.flash_messages));
  }, [window.flash_messages]);

  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={4} md={7} className={classes.image}>
        <img src={mainLogo} alt="logo" />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div>
            <Typography color="primary" variant="h6">
              {flashMessage.toString().split(/(?=[A-Z])/)[1]}
            </Typography>
          </div>
          <Avatar className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Diver - Login
          </Typography>
          <Form />
        </div>
      </Grid>
    </Grid>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <React.StrictMode>
      <Login />
    </React.StrictMode>,
    document.body.appendChild(document.createElement('div'))
  );
});
