import React, { useState } from 'react';
import { Typography, TextField, Grid, Button, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  link_forgot_password: {
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(',')
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function Form() {
  const classes = useStyles();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const siteKey = window.siteKeyRecaptchaLogin;
  const handleCaptchaChange = () => {
    setIsCaptchaVerified(true);
  };

  return (
    <form
      className={`${classes.form} login_admin_user`}
      id="session_new"
      action="/admin/login"
      noValidate="novalidate"
      acceptCharset="UTF-8"
      method="post"
    >
      <Input
        type="hidden"
        name="authenticity_token"
        value={window.authenticityToken}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="admin_user[email]"
        label="Email"
        type="email"
        id="admin_user_email"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="admin_user[password]"
        label="Senha"
        type="password"
        id="admin_user_password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        inputProps={{ maxLength: 16 }}
        fullWidth
        name="admin_user[otp_attempt]"
        label="Código 2FA (se ativo)"
        type="text"
        id="admin_user_otp_attempt"
      />
      <Input name="admin_user[remember_me]" type="hidden" value="0" />
      <Grid
        container
        alignContent="center"
        alignItems="center"
        direction="row"
        xs={5}
        md={5}
        lg={5}
      >
        <Typography>Lembrar da senha</Typography>
        &nbsp;
        <input
          value="1"
          name="admin_user[remember_me]"
          type="checkbox"
          id="admin_user_remember_me"
        />
      </Grid>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        name="commit"
        value="Enviar"
        className={classes.submit}
        disabled={!isCaptchaVerified}
      >
        Enviar
      </Button>
      <br />
      <a className={classes.link_forgot_password} href="/admin/password/new">
        Esqueceu sua senha?
      </a>

      <ReCAPTCHA sitekey={siteKey} onChange={handleCaptchaChange} />
    </form>
  );
}

export default Form;
